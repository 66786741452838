import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/heroImage"
import Profile from "../components/profile"

const About = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      office: file(relativePath: { eq: "office.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            src
            width
          }
        }
      }
    }
  `)

  const description =
    "司法書士法人みつわ合同事務所は、大阪の司法書士事務所です。司法書士法人みつわ合同事務所の概要、スタッフ、事務所の沿革をご紹介します。"

  return (
    <Layout>
      <SEO
        description={description}
        title="事務所概要 | 司法書士　大阪"
        pagepath={location.pathname}
        pageimg={data.office.childImageSharp.original.src}
        pageimgw={data.office.childImageSharp.original.width}
        pageimgh={data.office.childImageSharp.original.height}
      />
      <div className="main__container">
        <HeroImage
          name={"about"}
          fluid={data.office.childImageSharp.fluid}
          title={"司法書士法人みつわ合同事務所"}
          subTitle={"事務所概要"}
        />
        <Profile />
      </div>
    </Layout>
  )
}

export default About
