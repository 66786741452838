import React from "react"
import BackContact from "./backContact"

const Profile = () => {
  return (
    <div className="profile">
      <section className="profile__base">
        <div className="profile__base__container">
          <h2 className="profile__title">事務所概要</h2>
          <div className="profile__base">
            <dl className="profile__base__desc">
              <dt>名称</dt>
              <dd>司法書士法人みつわ合同事務所</dd>
              <dt>所在</dt>
              <dd className="profile__base__desc__addr">
                <div>
                  <p>〒541-0051</p>
                  <p>
                    <span>大阪市中央区備後町4丁目1番3号</span>
                    <span>御堂筋三井ビルディングB1F</span>
                  </p>
                </div>
              </dd>
              <dt>TEL／FAX</dt>
              <dd className="profile__base__desc__tel">
                <span>06-6222-7744</span>／<span>06-6222-7745</span>
              </dd>
            </dl>
          </div>
        </div>
      </section>
      <section className="profile__staff">
        <div className="profile__staff__container">
          <h2 className="profile__title">スタッフ</h2>
          <ul className="profile__staff__ul">
            <li className="profile__staff__li">
              <p className="profile__staff__name">
                <span className="profile__staff__title">所長</span>髙井雅弘
              </p>
              <p className="profile__staff__p">
                <span className="profile__staff__s">
                  簡裁代理権認定司法書士
                </span>
                <span className="profile__staff__number">
                  登録番号：大阪3171号
                </span>
              </p>
            </li>
            <li className="profile__staff__li">
              <p className="profile__staff__name">
                <span className="profile__staff__title"></span>石川寛
              </p>
              <p className="profile__staff__p">
                <span className="profile__staff__s">
                  簡裁代理権認定司法書士
                </span>
                <span className="profile__staff__number">
                  登録番号：大阪5266号
                </span>
              </p>
            </li>
            
            <li className="profile__staff__li">
              <p className="profile__staff__name">
                <span className="profile__staff__title"></span>山崎哲生
              </p>
              <p className="profile__staff__p">
                <span className="profile__staff__s">
                  簡裁代理権認定司法書士
                </span>
                <span className="profile__staff__number">
                  登録番号：大阪2804号
                </span>
              </p>
            </li>
            <li className="profile__staff__li">
              <p className="profile__staff__name">
                <span className="profile__staff__title">顧問</span>板谷雅孝
              </p>
              <p className="profile__staff__p">
                <span className="profile__staff__s">
                  簡裁代理権認定司法書士
                </span>
                <span className="profile__staff__number">
                  登録番号：大阪1466号
                </span>
              </p>
            </li>
            <p className="profile__staff__count">総勢８名</p>
          </ul>
        </div>
      </section>
      <section className="profile__history">
        <div className="profile__history__container">
          <h2 className="profile__title">事務所沿革</h2>
          <dl className="profile__history__desc">
            <dt>昭和４４年２月</dt>
            <dd>
              <p>今西祥介、司法書士今西祥介事務所を開設</p>
            </dd>
            <dt>平成１７年１月</dt>
            <dd>
              <p>司法書士今西・板谷合同事務所へ名称変更</p>
              <p>板谷雅孝、所長へ</p>
              <p>今西祥介、特別顧問へ</p>
            </dd>
            <dt>平成１７年１１月</dt>
            <dd>
              <p>事務所法人化</p>
              <p>司法書士法人今西・板谷合同事務所を設立</p>
            </dd>
            <dt>平成２７年１２月</dt>
            <dd>
              <p>司法書士法人みつわ合同事務所を設立</p>
              <p>髙瀬雅祥、所長へ</p>
              <p>髙井雅弘、副所長へ</p>
            </dd>
            <dt>平成２８年１月</dt>
            <dd>
              <p>司法書士法人みつわ合同事務所、</p>
              <p>司法書士法人今西・板谷合同事務所を承継</p>
              <p>板谷雅孝、顧問就任</p>
            </dd>
            <dt>令和４年４月</dt>
            <dd>
              <p>髙井雅弘、所長へ</p>
            </dd>
          </dl>
          <p>現在に至る</p>
        </div>
      </section>
      <BackContact link={"/"} linkName={"Topページ"} />
    </div>
  )
}

export default Profile
